<template>
    <div>
        <ts-page-title :title="$t('customerProfile.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <div class="tw-flex tw-space-x-3">
                        <Button type="info" ghost @click="addNew">
                            {{ $t('addNew') }}
                        </Button>
                        <Button
                            :waiting="waiting"
                            outline
                            @click.prevent="approveCustomer"
                        >
                            {{ $t('customerProfile.approved') }}
                        </Button>
                        <Dropdown
                            trigger="click"
                            class="ml-2"
                            @on-click="onClickExport"
                        >
                            <Button type="success" :waiting="exporting">
                                <i class="fas fa-file-excel"></i>
                                {{ $t('customerProfile.exportExcel') }}
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <template #list>
                                <DropdownMenu>
                                    <DropdownItem
                                        :name="1"
                                        :disabled="!checkedItems.length > 0"
                                    >
                                        <i class="fas fa-check-square"></i>
                                        {{
                                            $t(
                                                'customerProfile.selectedCustomer'
                                            )
                                        }}
                                    </DropdownItem>
                                    <DropdownItem :name="2">
                                        <i class="fas fa-users"></i>
                                        {{ $t('customerProfile.allCustomer') }}
                                    </DropdownItem>
                                </DropdownMenu>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="d-flex tw-space-x-3">
                        <RadioGroup
                            v-model="approveStatus"
                            @on-change="fetchData()"
                            type="button"
                        >
                            <Radio :label="null">
                                {{ $t('all') }}
                            </Radio>
                            <Radio :label="0">
                                {{ $t('customerProfile.pending') }}
                                <span class="badge bg-danger">{{
                                    pendingCount
                                }}</span>
                            </Radio>
                            <Radio :label="2">
                                {{ $t('customerProfile.approved') }}
                            </Radio>
                        </RadioGroup>
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('customerProfile.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-auto">
                <Table
                    :columns="columns"
                    :data="resources"
                    size="small"
                    :loading="loading"
                    max-height="700"
                    ref="selection"
                    @on-select="onSelectCheck"
                    @on-select-cancel="onSelectUncheck"
                    @on-select-all="onSelectAllCheck"
                    @on-select-all-cancel="onSelectAllUncheck"
                    stripe
                >
                    <template slot-scope="{ row }" slot="branch">
                        {{ row.branch ? row.branch.branch_name_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="customer_type">
                        {{ row.type ? row.type.customer_type : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="sale">
                        {{ row.sale ? row.sale.employee_name_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="is_general_customer">
                        <span
                            class="badge bg-success"
                            v-if="row.is_general_customer"
                            >Yes</span
                        >
                        <span class="badge bg-danger" v-else>No</span>
                    </template>
                    <template slot-scope="{ row }" slot="is_membership">
                        <span class="badge bg-success" v-if="row.is_membership"
                            >Yes</span
                        >
                        <span class="badge bg-danger" v-else>No</span>
                    </template>
                    <template slot-scope="{ row }" slot="approve_status">
                        <span
                            class="badge bg-danger"
                            v-if="row.approve_status == 0"
                            >Pending</span
                        >
                        <span
                            class="badge bg-success"
                            v-if="row.approve_status == 1"
                            >Partially Approved</span
                        >
                        <span
                            class="badge bg-success"
                            v-if="row.approve_status == 2"
                            >Approved</span
                        >
                        <span
                            class="badge bg-info"
                            v-if="row.approve_status == 3"
                            >Rejected</span
                        >
                    </template>
                    <template slot-scope="{ row }" slot="created_by">
                        {{
                            row.user
                                ? row.user
                                    ? row.user.employee
                                        ? row.user.employee.employee_name_en
                                        : ''
                                    : ''
                                : ''
                        }}
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <a
                            href="#"
                            class="text-primary ml-2"
                            @click.prevent="edit(row)"
                            v-tooltip="$t('edit')"
                        >
                            <Icon type="ios-create" size="20" />
                        </a>
                        <Poptip
                            confirm
                            :title="$t('areYouSure')"
                            @on-ok="deleteRecord(row)"
                            :transfer="true"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                        >
                            <a
                                class="ml-2 text-danger"
                                v-tooltip="$t('delete')"
                                :disabled="row._deleting"
                            >
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="row._deleting"
                                ></i>
                                <Icon type="ios-trash" size="20" v-else />
                            </a>
                        </Poptip>
                    </template>
                </Table>
            </div>
            <div class="d-flex justify-content-end p-15">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                scrollable
                :mask="false"
                footer-hide
                width="1200px"
                :title="$t('customerProfile.pageTitle')"
                centered
                :zIndex="1020"
                :closable="false"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12">
                    <label class="">{{
                        $t('customerProfile.branchName')
                    }}</label>
                    <Select
                        v-model="branch_id"
                        class="tw-w-full"
                        filterable
                        multiple
                        @on-change="changeBranch()"
                    >
                        <Option
                            v-for="branch in branchs"
                            :value="branch.value"
                            :key="branch.value"
                        >
                            {{ branch.label }}
                        </Option>
                    </Select>
                </div>
            </div>
            <!--  warehourse not apply yet -->
            <div class="row" v-if="false">
                <div class="col-md-12">
                    <label class="">{{
                        $t('customerProfile.warehourseName')
                    }}</label>
                    <Select
                        style="width: 257px"
                        v-model="location_id"
                        :options="locations"
                        showSearch
                        mode="multiple"
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        :maxTagCount="1"
                        :maxTagTextLength="17"
                        :placeholder="$t('all')"
                    >
                    </Select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label class="tw-mt-2">{{
                        $t('customerProfile.sale')
                    }}</label>
                    <Select
                        v-model="sale_id"
                        class="tw-w-full"
                        filterable
                        multiple
                    >
                        <Option
                            v-for="sale in sales"
                            :value="sale.employee_id"
                            :key="sale.employee_id"
                        >
                            {{ sale.employee_name_en }}
                        </Option>
                    </Select>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'
import FromAction from './form1.vue'

export default {
    name: 'customerProfileIndex',
    components: {
        FromAction
    },
    data () {
        return {
            waiting: false,
            loading: false,
            exporting: false,
            errors: new Errors(),
            showForm: false,
            branchs: [],
            locations: [],
            checkedItems: [],
            sales: [],
            width: 'width: 300px',
            visible: false
        }
    },
    computed: {
        ...mapState('customer/profile', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.customer.profile.search
            },
            set (newValue) {
                this.$store.commit('customer/profile/SET_SEARCH', newValue)
                this.$store.commit('customer/profile/RESET_CURRENT_PAGE')
            }
        },
        approveStatus: {
            get () {
                return this.$store.state.customer.profile.approve_status
            },
            set (newValue) {
                this.$store.commit(
                    'customer/profile/SET_APPROVED_STATUS',
                    newValue
                )
            }
        },
        location_id: {
            get () {
                return this.$store.state.customer.profile.location_id
            },
            set (newValue) {
                this.$store.commit('customer/profile/SET_LOCATION_ID', newValue)
            }
        },
        branch_id: {
            get () {
                return this.$store.state.customer.profile.branch_id
            },
            set (newValue) {
                this.$store.commit('customer/profile/SET_BRANCH_ID', newValue)
            }
        },
        sale_id: {
            get () {
                return this.$store.state.customer.profile.sale_id
            },
            set (newValue) {
                this.$store.commit('customer/profile/SET_SALE_ID', newValue)
            }
        },
        pendingCount () {
            return this.resources.filter(r => r.approve_status == 0).length
        },
        columns () {
            return [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: this.$t('customerProfile.branchName'),
                    slot: 'branch',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.customerCode'),
                    minWidth: 100,
                    key: 'customer_code'
                },
                {
                    title: this.$t('customerProfile.customerName'),
                    minWidth: 150,
                    key: 'customer_name'
                },
                {
                    title: this.$t('customerProfile.gender'),
                    minWidth: 100,
                    key: 'gender'
                },
                {
                    title: this.$t('customerProfile.phoneNumber'),
                    minWidth: 150,
                    key: 'contact_number'
                },
                {
                    title: this.$t('customerProfile.idCard'),
                    minWidth: 150,
                    key: 'ss_id_card'
                },
                {
                    title: this.$t('customerProfile.email'),
                    minWidth: 200,
                    key: 'contact_email'
                },
                {
                    title: this.$t('customerProfile.customerType'),
                    slot: 'customer_type',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.sale'),
                    slot: 'sale',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.dealerType'),
                    minWidth: 150,
                    key: 'dealer_type'
                },
                {
                    title: this.$t('customerProfile.grade'),
                    minWidth: 100,
                    key: 'dealer_grade'
                },
                {
                    title: this.$t('customerProfile.isGeneralCustomer'),
                    slot: 'is_general_customer',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.isMembership'),
                    slot: 'is_membership',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.status'),
                    slot: 'approve_status',
                    minWidth: 150
                },
                {
                    title: this.$t('customerProfile.discountRate'),
                    minWidth: 150,
                    key: 'membership_discount_rate'
                },
                {
                    title: this.$t('customerProfile.createdBy'),
                    slot: 'created_by',
                    minWidth: 150
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 100
                }
            ]
        }
    },
    created () {
        this.branch_id = [
            this.$store.state.authUser.user.default_branch.branch_id
        ]
        this.getBranch()
        this.getSaleList()
    },
    methods: {
        ...mapActions('customer/profile', ['getFormViewModel']),
        onSelectCheck (record) {
            this.setCustomerSelected(record)
        },
        onSelectUncheck (record) {
            this.setCustomerSelected(record)
        },
        onSelectAllCheck (record) {
            this.setCustomerSelected(record)
        },
        onSelectAllUncheck (record) {
            this.setCustomerSelected(record)
        },
        setCustomerSelected (records) {
            this.checkedItems = []
            if (records.length > 0) {
                records.map(row => {
                    this.checkedItems.push(row.customer_id)
                })
            }
        },
        getSaleList () {
            this.getFormViewModel({
                fnName: 'sale'
            }).then(response => {
                this.sales = response.data.sale
            })
        },
        onClickExport (type) {
            if (type == 1) {
                this.exportExcel(this.checkedItems)
            }
            if (type == 2) {
                this.exportExcel()
            }
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('customer/profile/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$toasted.error(error.message)
                })
        },
        addNew () {
            this.showForm = true
        },
        edit (record) {
            this.showForm = true
            this.$store.commit('customer/profile/SET_EDIT_DATA', record)
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.$refs.form_action.clearInput()
            this.$store.commit('customer/profile/SET_EDIT_DATA', {})
            this.showForm = false
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch('customer/profile/destroy', record.customer_id)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        getBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssignedForFilter')
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }))
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async changeBranch () {
            this.loading = true
            this.location_id = []
            // await this.$store
            //     .dispatch('auth/user/getWarehouseAssignedForFilter', {
            //         branch_id: this.branch_id
            //     })
            //     .then(response => {
            //         this.locations = response.data.map(el => ({
            //             value: el.location_id,
            //             label: el.location_name_en
            //         }))
            //     })
            //     .catch(error => {
            //         this.$toasted.error(error.message)
            //     })
            //     .finally(() => {
            //         this.loading = false
            //     })
        },
        exportExcel (checkedItems = []) {
            this.errors = new Errors()
            this.exporting = true

            this.$store
                .dispatch('customer/profile/customerExportExcel', {
                    customer_id: checkedItems
                })
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.notice({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.exporting = false))
        },
        approveCustomer () {
            if (!this.checkedItems.length) {
                this.$toasted.error('Please select any dealers.')
                return
            }
            this.waiting = true
            this.$store
                .dispatch('customer/profile/approveMulti', {
                    customer_id: this.checkedItems
                })
                .then(response => {
                    this.$toasted.success(response.message)
                    this.$refs.tableCustomer.checkedItems = []
                    this.checkedItems = []
                    this.fetchData()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PROFILE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        // location_id: debounce(function () {
        //     this.fetchData(this.search)
        // }, 500),
        sale_id: debounce(function () {
            this.fetchData(this.search)
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customer/profile/RESET_STATE')
        this.$store.commit('customer/profile/CLEAR_STATE')
        next()
    }
}
</script>
