<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="row">
                <div class="col-md-3">
                    <div
                        class="tw-w-full tw-h-64 tw-rounded-lg tw-border tw-shadow-md tw-p-2"
                    >
                        <img
                            :src="showImage"
                            @error="onImagError"
                            class="tw-w-full tw-h-full tw-object-contain"
                        />
                    </div>
                </div>
                <div class="col-md-9 tw-space-y-2">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs required">{{
                                $t('customerProfile.customerType')
                            }}</label>
                            <Select
                                v-model="model.cus_type_id"
                                class="tw-w-full"
                                filterable
                            >
                                <Option
                                    v-for="cus_type in customerType"
                                    :value="cus_type.cus_type_id"
                                    :key="cus_type.cus_type_id"
                                >
                                    {{ cus_type.customer_type }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('cus_type_id')"
                            >
                                {{ errors.first('cus_type_id') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>{{ $t('customerProfile.sale') }}</label>
                            <Select
                                v-model="model.sale_id"
                                class="tw-w-full"
                                filterable
                            >
                                <Option
                                    v-for="sale in sales"
                                    :value="sale.employee_id"
                                    :key="sale.employee_id"
                                >
                                    {{ sale.employee_name_en }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('sale_id')"
                            >
                                {{ errors.first('sale_id') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs required">{{
                                $t('customerProfile.customerName')
                            }}</label>
                            <input
                                v-model="model.customer_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('customer_name')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('customer_name')"
                            >
                                {{ errors.first('customer_name') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs required">{{
                                $t('customerProfile.gender')
                            }}</label>
                            <div class="tw-w-full">
                                <RadioGroup v-model="model.gender">
                                    <Radio label="Male">Male</Radio>
                                    <Radio label="Female">Female</Radio>
                                    <Radio label="Other">Other</Radio>
                                </RadioGroup>
                            </div>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('gender')"
                            >
                                {{ errors.first('gender') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="tw-text-xs required">{{
                                $t('customerProfile.phoneNumber')
                            }}</label>
                            <input
                                v-model.trim="model.contact_number"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('contact_number')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('contact_number')"
                            >
                                {{ errors.first('contact_number') }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.phoneNumber2')
                            }}</label>
                            <input
                                v-model.trim="model.contact_number2"
                                type="text"
                                class="form-control"
                            />
                        </div>
                        <div class="col-md-3">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.idCard')
                            }}</label>
                            <input
                                v-model.trim="model.ss_id_card"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('ss_id_card')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('ss_id_card')"
                            >
                                {{ errors.first('ss_id_card') }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.fbAccount')
                            }}</label>
                            <input
                                v-model.trim="model.facebook_account"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('facebook_account')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('facebook_account')"
                            >
                                {{ errors.first('facebook_account') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.telegram')
                            }}</label>
                            <input
                                v-model.trim="model.telegram"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('telegram')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('telegram')"
                            >
                                {{ errors.first('telegram') }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.email')
                            }}</label>
                            <input
                                v-model.trim="model.contact_email"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('contact_email')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('contact_email')"
                            >
                                {{ errors.first('contact_email') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.companyName')
                            }}</label>
                            <input
                                v-model.trim="model.company_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('company_name')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('company_name')"
                            >
                                {{ errors.first('company_name') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.accountNumber')
                            }}</label>
                            <input
                                v-model.trim="model.account_number"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('account_number')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('account_number')"
                            >
                                {{ errors.first('account_number') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.ownerName')
                            }}</label>
                            <input
                                v-model.trim="model.owner_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('owner_name')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('owner_name')"
                            >
                                {{ errors.first('owner_name') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <Checkbox v-model="model.is_membership">
                                {{ $t('customerProfile.isMembership') }}
                            </Checkbox>
                            <input
                                v-model.number="model.membership_discount_rate"
                                type="number"
                                class="form-control tw-mt-2"
                                :disabled="!model.is_membership"
                                :class="{
                                    'is-invalid': errors.has(
                                        'membership_discount_rate'
                                    )
                                }"
                            />
                            <small
                                ><i>{{
                                    $t('customerProfile.discountRate')
                                }}</i></small
                            >
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('membership_discount_rate')"
                            >
                                {{ errors.first('membership_discount_rate') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                class="tw-grid tw-grid-cols-2 tw-gap-3 tw-pt-2"
                            >
                                <div>
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.creditDay')
                                    }}</label>
                                    <input
                                        v-model.number="model.credit_day"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                errors.has('credit_day')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback tw-text-xs"
                                        v-if="errors.has('credit_day')"
                                    >
                                        {{ errors.first('credit_day') }}
                                    </div>
                                </div>
                                <div>
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.creditAmount')
                                    }}</label>
                                    <input
                                        v-model.number="model.credit_amount"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                errors.has('credit_amount')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback tw-text-xs"
                                        v-if="errors.has('credit_amount')"
                                    >
                                        {{ errors.first('credit_amount') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="tw-text-xs">{{
                                        $t('customerProfile.type')
                                    }}</label>
                                    <Select
                                        v-model="model.dealer_type_id"
                                        class="tw-w-full"
                                        filterable
                                    >
                                        <Option
                                            v-for="dealerType in dealerTypes"
                                            :value="dealerType.dealer_type_id"
                                            :key="dealerType.dealer_type_id"
                                        >
                                            {{ dealerType.dealer_type }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('dealer_type_id')"
                                    >
                                        {{ errors.first('dealer_type_id') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>{{
                                        $t('customerProfile.grade')
                                    }}</label>
                                    <Select
                                        v-model="model.dealer_grade"
                                        class="tw-w-full"
                                        filterable
                                    >
                                        <Option
                                            v-for="dealerGrade in dealerGrades"
                                            :value="dealerGrade.grade"
                                            :key="dealerGrade.grade"
                                        >
                                            {{ dealerGrade.grade }}
                                        </Option>
                                    </Select>
                                    <div
                                        class="validate tw-text-xs"
                                        v-if="errors.has('dealer_grade')"
                                    >
                                        {{ errors.first('dealer_grade') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs required">{{
                                $t('customerProfile.status')
                            }}</label>
                            <Select
                                v-model="model.approve_status"
                                class="tw-w-full"
                            >
                                <Option :value="0">Pending</Option>
                                <Option :value="1">
                                    Partially Approved
                                </Option>
                                <Option :value="2">Approved</Option>
                                <Option :value="3">Rejected</Option>
                            </Select>
                        </div>
                    </div>
                    <br />
                    <div class="row tw-mt-4">
                        <div class="col-md-12">
                            <h4
                                class="tw-uppercase tw-text-gray-500 tw-bg-gray-200 tw-p-1"
                            >
                                {{ $t('customerProfile.address') }}
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.province')
                            }}</label>
                            <Select
                                v-model="model.province_code"
                                @on-change="onProvinceChange"
                                class="tw-w-full"
                                filterable
                            >
                                <Option
                                    v-for="province in provinces"
                                    :value="province.province_code"
                                    :key="province.province_code"
                                    :label="province.name_en"
                                >
                                    {{ province.name_en }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('province_code')"
                            >
                                {{ errors.first('province_code') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.district')
                            }}</label>
                            <Select
                                v-model="model.district_code"
                                filterable
                                @on-change="onDistrictChange"
                                class="tw-w-full"
                            >
                                <Option
                                    v-for="district in districts"
                                    :value="district.district_code"
                                    :key="district.district_code"
                                    :label="district.name_en"
                                >
                                    {{ district.name_en }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('district_code')"
                            >
                                {{ errors.first('district_code') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.commune')
                            }}</label>
                            <Select
                                v-model="model.commune_code"
                                @on-change="onCommuneChange"
                                filterable
                                class="tw-w-full"
                            >
                                <Option
                                    v-for="commune in communes"
                                    :value="commune.commune_code"
                                    :key="commune.commune_code"
                                    :label="commune.name_en"
                                >
                                    {{ commune.name_en }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('commune_code')"
                            >
                                {{ errors.first('commune_code') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.village')
                            }}</label>
                            <Select
                                v-model="model.village_code"
                                filterable
                                class="tw-w-full"
                            >
                                <Option
                                    v-for="village in villages"
                                    :value="village.village_code"
                                    :key="village.village_code"
                                    :label="village.name_en"
                                >
                                    {{ village.name_en }}
                                </Option>
                            </Select>
                            <div
                                class="validate tw-text-xs"
                                v-if="errors.has('village_code')"
                            >
                                {{ errors.first('village_code') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.zipCode')
                            }}</label>
                            <input
                                v-model.trim="model.zip_code"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('zip_code')
                                }"
                            />
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('zip_code')"
                            >
                                {{ errors.first('zip_code') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="tw-text-xs">{{
                                $t('customerProfile.gisLocation')
                            }}</label>
                            <div class="tw-relative">
                                <input
                                    v-model.trim="model.gis_location"
                                    type="text"
                                    class="form-control"
                                    readonly
                                    :class="{
                                        'is-invalid': errors.has('gis_location')
                                    }"
                                />
                                <a
                                    href="#"
                                    class="tw-absolute tw-top-0 tw-right-0 tw-mr-2 tw-mt-2 tw-rounded-lg tw-px-1 tw-border tw-border-blue-500"
                                    @click="() => (show_map = true)"
                                    ><i class="fa fa-map-marked"></i
                                ></a>
                            </div>
                            <div
                                class="invalid-feedback tw-text-xs"
                                v-if="errors.has('gis_location')"
                            >
                                {{ errors.first('gis_location') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                                <ts-checkbox
                                    v-model="model.is_general_customer"
                                >
                                    {{
                                        $t('customerProfile.isGeneralCustomer')
                                    }}
                                </ts-checkbox>
                                <ts-checkbox
                                    v-model="model.is_allow_update_gps_location"
                                >
                                    {{ $t('customerProfile.allowUpdateGPS') }}
                                </ts-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 tw-mt-3 tw-text-right">
                            <ts-button
                                class="tw-mr-2"
                                @click.prevent="() => $emit('cancel')"
                            >
                                {{ $t('cancel') }}
                            </ts-button>
                            <ts-button
                                v-if="!isUpdate"
                                color="primary"
                                @click.prevent="onSave"
                                :waiting="waiting"
                                >{{ $t('save') }}</ts-button
                            >
                            <ts-button
                                v-if="isUpdate"
                                color="primary"
                                @click.prevent="onUpdate"
                                :waiting="waiting"
                                >{{ $t('update') }}</ts-button
                            >
                        </div>
                    </div>
                </div>
            </div>
        </ts-loading-banner>

        <Modal
            v-if="show_map"
            v-model="show_map"
            :title="$t('customerProfile.gisLocation')"
            centered
            :zIndex="1020"
            :footer="null"
        >
            <!-- :zIndex="1020" -->
            <TsMapAutocomplete @setLocation="setPlaceOrLocation" />
            <GmapMap
                :center="center"
                :zoom="zoom"
                :options="{
                    streetViewControl: true
                }"
                map-type-id="roadmap"
                class="ts-w-full ts-h-48 ts-mt-2"
                @click="mapClick"
            >
                <GmapMarker
                    :clickable="true"
                    :draggable="true"
                    :position="marker"
                />
            </GmapMap>
        </Modal>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'companyForm',
    data () {
        return {
            loading: false,
            show_map: false,
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            districts: [],
            communes: [],
            villages: [],
            dealerTypes: [],
            dealerGrades: [],
            customerType: [],
            sales: [],
            provinces: [],
            model: {
                customer_id: null,
                cus_type_id: null,
                customer_name: null,
                gender: null,
                contact_number: null,
                contact_email: null,
                facebook_account: null,
                city_province: null,
                year_of_born: null,
                account_number: null,
                is_general_customer: false,
                is_membership: false,
                is_allow_update_gps_location: false,
                membership_discount_rate: 0,
                company_name: null,
                zip_code: null,
                credit_day: 0,
                credit_amount: 0,
                province_code: null,
                disctrict_code: null,
                commune_code: null,
                village_code: null,
                sale_id: null,
                ss_id_card: null,
                gis_location: '',
                owner_name: null,
                telegram: null,
                approve_status: 2, // default is approved
                dealer_type_id: undefined,
                dealer_grade: undefined,
                contact_number2: null
            },
            zoom: 7,
            center: { lat: 12.565679, lng: 104.990967 },
            marker: { lat: 0, lng: 0 }
        }
    },
    created () {
        this.setEditData()
        this.fetchResources()
    },
    computed: {
        ...mapState('customer/profile', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        showImage () {
            if (!isEmpty(this.edit_data)) {
                if (this.edit_data.front_photo1 != null) {
                    return this.edit_data.front_photo
                }
                return 'http://dslv9ilpbe7p1.cloudfront.net/wQ1sJi1CCJpI87JfbO8nrA_store_header_image'
            }
            return 'http://dslv9ilpbe7p1.cloudfront.net/wQ1sJi1CCJpI87JfbO8nrA_store_header_image'
        }
    },
    methods: {
        ...mapActions('customer/profile', ['getFormViewModel']),
        onSave () {
            this.waiting = true
            this.$store
                .dispatch('customer/profile/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('customer/profile/update', {
                    id: this.model.customer_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async fetchResources () {
            this.loading = true
            await this.fetchFormViewModel()
            this.loading = false
        },
        async fetchFormViewModel () {
            let response = await this.getFormViewModel({
                fnName: 'dealerType,dealerGrade,sale,customerType,provinces'
            })

            this.customerType = response.data['customerType']

            this.sales = response.data['sale']

            this.dealerTypes = response.data['dealerType']

            this.dealerGrades = response.data['dealerGrade']

            this.provinces = response.data['provinces']
        },
        async onProvinceChange (value) {
            this.model.disctrict_code = null
            this.model.commune_code = null
            this.model.village_code = null
            this.loading = true

            await this.getFormViewModel({
                fnName: 'districts',
                province_code: value
            })
                .then(response => {
                    this.districts = response.data['districts']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onDistrictChange (value) {
            this.model.commune_code = null
            this.model.village_code = null
            this.loading = true
            await this.getFormViewModel({
                fnName: 'communes',
                district_code: value
            })
                .then(response => {
                    this.communes = response.data['communes']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onCommuneChange (value) {
            this.model.village_code = null
            this.loading = true
            await this.getFormViewModel({
                fnName: 'villages',
                commune_code: value
            })
                .then(response => {
                    this.villages = response.data['villages']
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.customer_id = this.edit_data.customer_id
                this.model.cus_type_id = this.edit_data.cus_type_id
                this.model.customer_name = this.edit_data.customer_name
                this.model.gender = this.edit_data.gender
                this.model.contact_number = this.edit_data.contact_number
                this.model.contact_email = this.edit_data.contact_email
                this.model.facebook_account = this.edit_data.facebook_account
                this.model.city_province = this.edit_data.city_province
                this.model.year_of_born = this.edit_data.year_of_born
                this.model.account_number = this.edit_data.account_number
                this.model.is_general_customer =
                    this.edit_data.is_general_customer
                this.model.company_name = this.edit_data.company_name
                this.model.zip_code = this.edit_data.zip_code
                this.model.is_membership = this.edit_data.is_membership
                this.model.ss_id_card = this.edit_data.ss_id_card
                this.model.is_allow_update_gps_location =
                    this.edit_data.is_allow_update_gps_location
                this.model.credit_day = this.edit_data.credit_day
                this.model.credit_amount = this.edit_data.credit_amount
                this.model.membership_discount_rate = this.edit_data
                    .membership_discount_rate
                    ? this.edit_data.membership_discount_rate
                    : 0
                this.model.sale_id = this.edit_data.sale_id
                this.model.owner_name = this.edit_data.owner_name
                this.model.telegram = this.edit_data.telegram
                this.model.approve_status = this.edit_data.approve_status
                this.model.dealer_type_id = this.edit_data.dealer_type_id
                this.model.dealer_grade = this.edit_data.dealer_grade

                if (this.edit_data.province_code) {
                    await this.onProvinceChange(this.edit_data.province_code)
                    await this.onDistrictChange(this.edit_data.district_code)
                    await this.onCommuneChange(this.edit_data.commune_code)
                }

                this.model.province_code = this.edit_data.province_code
                this.model.district_code = this.edit_data.district_code
                this.model.commune_code = this.edit_data.commune_code
                this.model.village_code = this.edit_data.village_code

                var gisLocation = this.edit_data.gis_location

                if (gisLocation) {
                    this.center = {
                        lat: gisLocation[0].lat,
                        lng: gisLocation[0].lng
                    }
                    this.marker = this.center
                    this.zoom = 18
                    this.model.gis_location =
                        gisLocation[0].lng + ', ' + gisLocation[0].lat
                }
            }
        },
        clearInput () {
            this.model.customer_id = null
            this.model.cus_type_id = null
            this.model.customer_name = null
            this.model.gender = null
            this.model.contact_number = null
            this.model.contact_email = null
            this.model.facebook_account = null
            this.model.city_province = null
            this.model.year_of_born = null
            this.model.account_number = null
            this.model.is_general_customer = false
            this.model.is_membership = false
            this.model.is_allow_update_gps_location = false
            this.model.membership_discount_rate = 0
            this.model.company_name = null
            this.model.zip_code = null
            this.model.credit_day = 0
            this.model.credit_amount = 0
            this.model.province_code = null
            this.model.disctrict_code = null
            this.model.commune_code = null
            this.model.village_code = null
            this.model.sale_id = null
            this.model.ss_id_card = null
            this.model.gis_location = ''
            this.model.owner_name = null
            this.model.telegram = null
            this.model.approve_status = 2 // default is approved
            this.model.dealer_type_id = undefined
            this.model.dealer_grade = undefined
            this.model.contact_number2 = null
        },
        setPlaceOrLocation (location) {
            this.center = {
                lat: location.lat,
                lng: location.lng
            }
            this.marker = this.center
            this.zoom = 18
            this.model.gis_location = location.lng + ', ' + location.lat
        },
        mapClick (value) {
            this.marker.lat = value.latLng.lat()
            this.marker.lng = value.latLng.lng()
            this.model.gis_location =
                value.latLng.lng() + ', ' + value.latLng.lat()
        },
        onImagError (e) {
            e.target.src =
                'http://dslv9ilpbe7p1.cloudfront.net/wQ1sJi1CCJpI87JfbO8nrA_store_header_image'
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'CUSTOMER',
                desc: not.text
            })
        }
    }
}
</script>
